import React from "react"
import NoheaderFooter from "../../../../../components/Noheaderfooter"
import SEO from "../../../../../components/seo"
import FaqsBusinessMobile from "../../../../../components/body/pages/mobile/ng/legal/faq/faqBusiness"

const FAQSBusinessPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/en-ng/legal/faq/business"}
      title="Frequently Asked Questions Business | Kuda | The Money App for Africans"
      description="Get a free debit card, no fees."
    />
    <FaqsBusinessMobile />
  </NoheaderFooter>
)

export default FAQSBusinessPage
